<script setup lang="ts">
import {computed} from 'vue';
import {usePage} from "@inertiajs/inertia-vue3";

const emit = defineEmits(['update:checked']);

const props = defineProps({
	checked: {
		type: Number,
		default: 0,
	},
	value: {
		default: null,
	},
	userInteractionDisabled: {
		type: Boolean
	},
	id: String
});

const page = usePage();

const proxyChecked = computed({
	get() {
		return props.checked === 1;
	},

	set(val) {
		if (props.userInteractionDisabled) return;
		emit('update:checked', {newVal: val, object: props.value, id: props.id});
	},
});
</script>

<template>
	<label :for="'default-toggle'+id" class="inline-flex h-6 relative items-center cursor-pointer"
	       :class="{'pointer-events-none opacity-60': userInteractionDisabled}">
		<input :disabled="userInteractionDisabled" type="checkbox" :value="value" v-model="proxyChecked"
		       :id="'default-toggle'+id" class="sr-only peer">
		<span
				:class="checked ? 'bg-primary' : 'bg-gray-200'"
				class="w-11 h-5 bg-gray-100 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-[140%] peer-checked:after:border-white after:content-['']
				after:absolute after:top-[4px] after:left-[3px] after:bg-white after:border-gray-100 after:border after:rounded-full after:h-4 after:w-4 after:transition-all "></span>
	</label>
</template>
